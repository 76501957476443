/**
 * @title errorInfo
 * @chapter ERROR_INFO
 * @summary
 * 
 * ```js
 * function errorInfo(error, separator = '')
 * ```
 * Extract a human readable string form the error object, starting from the most meaningful field, falling back to the least meaningful one if needed.<br>
 * Return an empty string if the error cannot be converted to a valid string<br>
 *
 * ```
 * var error = new Error('You broke my heart!');
 * console.error("Why you're crying?? "+errorInfo(error));
 * // prints 'Why you're crying?? You broke my heart!'
 * ```
 *
 * @param error The error to be displayed
 * @param separator prepend this string to the error string if the error is valorized
 *
 * @return A human readable string error
 */
export function errorInfo(error, separator = ''){
    let str = '';
    if (error.error && error.error.message) {
        // this is the standard LB4 error structure:
        // {error:{statusCode: number, messge: string}}
        str = error.error.message;
    } else {
        str = error.text ||
            error.message ||
            (typeof error.originalError === 'string' ? error.originalError : null) ||
            error.label ||
            error.statusCode ||
            error.code ||
            (error.originalError || {}).message ||
            JSON.stringify(error);
    }
    if (str && str.length > 0) return separator + str;
    return '';
};