import React from 'react';
import PropTypes from 'prop-types';
import './loginTemplatePage.css';

export default class LoginTemplatePage extends React.PureComponent {
    static propTypes = {
        children: PropTypes.array,
        leftBackground: PropTypes.string
    };

    constructor (props) {
        super(props);

        this.leftBackgroundStyle = {
            backgroundImage: 'url(' + this.props.leftBackground + ')'
        };
    }

    render = () =>
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-6 d-none d-lg-block">
                    <div className="bg-cover h-100 min-vh-100 ml-n3" style={this.leftBackgroundStyle}></div>
                </div>
                <div className="d-none d-lg-block col-lg-1"></div>
                <div className="col-10 col-sm-8 col-md-6 col-lg-4 align-self-center px-lg-5 mb-5">
                    {this.props.children}
                </div>
                <div className="d-none d-lg-block col-lg-1"></div>
            </div>
        </div>;
}

