import { queryStringBuilder } from './queryStringBuilder';

/**
 * Default basic fetch config
 */
export const fetchCommonConfig = {
    cache: 'no-cache',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
    credentials: 'same-origin'
};

/**
 * Basic async fetch wrapper function
 * Return the response json body on success, or throw it on failure.
 * 
 * @param url 
 * @param config
 */
async function beseRequest(url, config){
    // don't catch this. Callers must catch everything from this method
    const response = await fetch(url, config);

    // Read the response body as text instead of json.
    // This is used to prevent response.json() to fail ob empty bodies(like for http 204)
    // Empty bodies are not an error, but throwing for failing to decode the empty body as json is an error.
    // The json conversion is done manually to manage the empty body case
    let responseBody = await response.text();

    // some get responses does not have a json body (like http 204).
    // Just don't try to decode it in case it is absent
    if (responseBody && responseBody.trim()){
        try{
            responseBody = JSON.parse(responseBody);
        }catch(e){
            console.error('Body parse failed: '+e.message);      
            const error = new Error('Body parse failed: '+e.message);
            error.detail = {
                method: config.method,
                url: url,
                partialResponseBody: responseBody.substring(0,10000)
            };
            throw error;
        }
    }else{
        responseBody = null;
    }

    // if ok, just return the responseBody whatever it is
    if (response.ok){
        return responseBody;
    }

    // response was not ok. Throw the response body
    console.error('Rest request failed: ', response);

    // for response body objects, attach some more infos
    if (responseBody && typeof responseBody == 'object'){
        responseBody.status = response.status;
        responseBody.statusText = response.statusText;
    }

    throw responseBody;
}

/**
 * @chapter HTTP_CLIENT
 * @title httpGET
 * @summary
 * 
 * ```js
 * async function httpGET(url, parameters = {}, customConfig = {})
 * ```
 * 
 * [Fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API) based http get helper function.
 * Perform a GET request using JSON request/response parameters.
 * This function will throw an error in case of both internal errors and http errors.
 * 
 * ```js
 * import {httpGET} from '@mitech/onit-next-react-components/dist/http'
 * cont response = await httpGET('/some/url',{querystringValue:1, otherQueryStringValue:2});
 * console.log(await response.json())
 * ```
 * 
 * @param url The rest http url
 * @param parametrs Querystring parameters. See queryStringBuilder for conversion rules.
 * @param customConfig Js Fetch configuration object 
 * @returns A promise resolving a [fetch response object](https://developer.mozilla.org/en-US/docs/Web/API/Response) 
 */
export async function httpGET(url, parameters = {}, customConfig = {}){
    const _parameters = queryStringBuilder(parameters);
    const _conf = Object.assign({}, fetchCommonConfig, customConfig, {
        method: 'GET'
    });
    return beseRequest(url + _parameters, _conf);
}

/**
 * @chapter HTTP_CLIENT
 * @title httpPOST
 * @summary
 * 
 * ```js
 * async function httpPOST(url, body, customConfig = {})
 * ```
 * 
 * [Fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API) based http post helper function.
 * Perform a POST request using JSON as request and reposnse content type.
 * The passed in body is attached at the request.
 * NOTE: This function will throw in case of internal fetch error and server http error(like 404/500)
 * 
 * ```js
 * import {httpPOST} from '@mitech/onit-next-react-components/dist/http'
 * const response = await httpPOST('/some/url/', { body object})
 * console.log(await response.json())
 * ```
 * 
 * Empty responses will make this method to return a nullish body.
 * 
 * @param url The rest http url
 * @param body Body to be sent to rest service 
 * @param customConfig Js Fetch configuration object 
 * @returns A promise resolving a [fetch response object](https://developer.mozilla.org/en-US/docs/Web/API/Response)
 */
export async function httpPOST(url, body, customConfig = {}){
    const _conf = Object.assign({}, fetchCommonConfig, customConfig, {
        method: 'POST',
        body: JSON.stringify(body)
    });
    return beseRequest(url, _conf);
};

/**
 * @chapter HTTP_CLIENT
 * @title httpPUT
 * @summary
 * 
 * ```js
 * async function httpPUT(url, body = {}, customConfig = {})
 * ```
 * 
 * [Fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API) based http put helper function.
 * Perform a PUT request using JSON as request and reposnse content type.
 * The passed in body is attached at the request.
 * NOTE: This function will throw in case of internal fetch error and server http error(like 404/500)
 * ```js
 * import {httpPUT} from '@mitech/onit-next-react-components/dist/http'
 * const response = await httpPUT('/some/url/', { body object})
 * console.log(await response.json())
 * ```
 * Empty responses will make this method to return a nullish body.
 * @param url The rest http url
 * @param body Body to be sent to rest service 
 * @param customConfig Js Fetch configuration object.
 * @returns A promise resolving a [fetch response object](https://developer.mozilla.org/en-US/docs/Web/API/Response)
 */
export async function httpPUT(url, body, customConfig = {}){
    const _conf = Object.assign({}, fetchCommonConfig, customConfig, {
        method: 'PUT',
        body: JSON.stringify(body)
    });
    return beseRequest(url, _conf);
};

/**
 * @chapter HTTP_CLIENT
 * @title httpPATCH
 * @summary
 * 
 * ```js
 * async function httpPATCH(url, body = {}, customConfig = {})
 * ```
 * 
 * [Fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API) based http patch helper function.
 * Perform a PATCH request using JSON as request and reposnse content type.
 * The passed in body is attached at the request.
 * NOTE: This function will throw in case of internal fetch error and server http error(like 404/500)
 * ```js
 * import {httpPATCH} from '@mitech/onit-next-react-components/dist/http'
 * const response = await httpPATCH('/some/url/', { body object})
 * console.log(await response.json())
 * ```
 * Empty responses will make this method to return a nullish body.
 * @param url The rest http url
 * @param body Body to be sent to rest service  
 * @param customConfig Js Fetch configuration object 
 * @returns A promise resolving a [fetch response object](https://developer.mozilla.org/en-US/docs/Web/API/Response)
 */
export async function httpPATCH(url, body, customConfig = {}){

    const _conf = Object.assign({}, fetchCommonConfig, customConfig, {
        method: 'PATCH',
        body: JSON.stringify(body)
    });
    return beseRequest(url, _conf);
};

/**
 * @chapter HTTP_CLIENT
 * @title httpDELETE
 * @summary
 * 
 * ```js
 * async function httpDELETE(url, customConfig = {})
 * ```
 * 
 * [Fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API) based http delete helper function.
 * Perform a DELETE request using JSON as request and reposnse content type.
 * The passed in body is attached at the request.
 * NOTE: This function will throw in case of internal fetch error and server http error(like 404/500)
  * ```js
 * import {httpDELETE} from '@mitech/onit-next-react-components/dist/http'
 * const response = await httpDELETE('/some/url');
 * ```
 * Empty responses will make this method to return a nullish body.
 * @param url 
 * @param customConfig Js Fetch configuration object 
 * @returns A promise resolving a [fetch response object](https://developer.mozilla.org/en-US/docs/Web/API/Response)
 */
export async function httpDELETE(url, parameters = {}, customConfig = {}) {
    const _parameters = queryStringBuilder(parameters);
    const _conf = Object.assign({}, fetchCommonConfig, customConfig, {
        method: 'DELETE'
    });
    return beseRequest(url + _parameters, _conf);
};