import React from 'react';

/**
 * @title i18n
 * @chapter I18N
 * @summary
 * 
 * Internationalization handler. This handler return the label as plain text.
 * 
 * ```js
 * import {i18nHtml} from '@mitech/onit-next-react-components/dist/i18n'
 * console.log(i18n('translateLabel'))
 * ```
 *
 * @param label  The label to be translated
 * @param replaceValues  the list of values to be replaced to %s in the label translated text (like sprintf)
 * example: 
 * - translated label = 'hi %s'
 * - replaceValues = 'ivan!'
 * - result 'hi ivan!'
 * An arbitrary number of %s is supported, just provide the right amount of parameters
 * @returns The translated string
 */
export function i18n(label, ...replaceValues) {
    const l = (window.LABELS && window.LABELS[label]) ? window.LABELS[label] : '[label: ' + label + ']';
    return l.replace(/%s/g, () => { 
        const v = replaceValues.shift(); 
        if (v === null || v === undefined) return ''; 
        return v;
    });
}

/**
 * @title i18nHtml
 * @chapter I18N
 * @summary
 *
 * Internationalization handler. This handler suports html styling, but use with care since
 * it's implemented by dangerouslySetInnerHTML
 * https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 *
 * ```js
 * import {i18nHtml} from '@mitech/onit-next-react-components/dist/i18n'
 * console.log(i18nHtml('translateLabel'))
 * ```
 *
 * @param label  The label to be translated
 * @param replaceValues  the list of values to be replaced to %s in the label translated text (like sprintf)
 * example: 
 * - translated label = 'hi %s'
 * - replaceValues = 'ivan!'
 * - result 'hi ivan!'
 * An arbitrary number of %s is supported, just provide the right amount of parameters
 * @returns The translated string or a react element.
 */
export function i18nHtml(label, ...replaceValues){
    let l = i18n(label, ...replaceValues);

    // do the html stuff only if strictly required (we must have some sort of open tag and close tag )
    const openTag = l.indexOf('<');
    if ((openTag >= 0) && (l.indexOf('</') >= openTag)) {
        l = React.createElement('span', { dangerouslySetInnerHTML: { __html: l } });
    }
    return l;
}